@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: rgb(209,213,219);
}

body {
  background-color: rgb(209,213,219);
}

.udemy {
 background-image: url("assets/img/udemy.jpg");
 background-size: 100%;
 width: 100%;
 height: 100%;
}

.jetbrains {
 background-image: url("assets/img/jetbrains.jpg");
 background-size: 100%;
 width: 100%;
 height: 100%;
}

.highlight-it {
  animation: shake 500ms cubic-bezier(0, 0, 0.2, 1) 1;
}


@keyframes shake {
  10% { transform: rotate(1deg); }
  30% { transform: rotate(-2deg); }
  50% { transform: rotate(2deg); }
  70% { transform: rotate(-2deg); }
  90% { transform: rotate(2deg); }
  100% { transform: rotate(-1deg); }

}
